import React, {useState, useEffect} from "react";
import "./App.css";
import "./CorporateFacelift.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Homepage from "./views/Homepage";
// import NavBar from "./components/Navbar";
import QubeTokenomics from "./views/QubeTokenomics";
import Philosophy from "./views/Philosophy";
import Faqs from "./views/Faqs";
import HqIsNewAi from "./views/news-content/HqIsNewAi";
import NextGenArchitecture from "./views/news-content/NextGenArchitecture";
import AchitectureAiAndBlockchain from "./views/blogs-content/AchitectureAiAndBlockchain";
import IsHomeBuildingRipeForARevolutionary from "./views/blogs-content/IsHomeBuildingRipeForARevolutionary";
import NFTSforHomePartsandDesign from "./views/blogs-content/NFTSforHomePartsandDesign";
import OurQubesTowardCarbonNeutrality from "./views/blogs-content/OurQubesTowardCarbonNeutrality";
import PrintanEntireHouse from "./views/blogs-content/PrintanEntireHouse";
import TheFutureOfBuiltEnivorment from "./views/blogs-content/TheFutureOfBuiltEnivorment";
import WhatIsBottomUpApproarch from "./views/blogs-content/WhatIsBottomUpApproarch";
import Terms from "./views/Terms";
import Policy from "./views/Policy";
import DAOProjects from "./views/DAO-Projects";
import StateofPlay from "./views/StateofPlay";
import ScrollToTop from "./ScrollToTop";
import NewNavbar from "./components/NewNavbar";
import BuyQubes from "./views/Buy-Qubes";
import SuccessStripePayment from "./components/SuccessStripePayment";
import AIChatBot from "./components/Floaters/AiChatBot";
import Footer3 from "./components/Footer3/Footer3";
import PageNotFound from './views/PageNotFound';
import AudioToggle from './components/Floaters/AudioToggle'

function App() {

  const [isAudioOn, setIsAudioOn] = useState(false);

  // Audio control logic
  const audio = new Audio('../../sounds/audio.mp3'); // Replace with your actual audio file path
  audio.loop = true;

  useEffect(() => {
    if (isAudioOn) {
      audio.play();
    } else {
      audio.pause();
    }

    // Cleanup audio on component unmount
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, [isAudioOn]);

  const toggleAudio = () => {
    setIsAudioOn((prev) => !prev);
  };

  return (
    <>
      <Router>
        <ScrollToTop>
          <Routes>
            <Route path="*" 
            element={
            <>
              <NewNavbar />
              <PageNotFound />
              <Footer3 />
            </>
            } />
            <Route
              exact={true}
              path="/"
              element={
                <>
                  <NewNavbar />
                  <Homepage />
                  <AIChatBot />
                  <AudioToggle isAudioOn={isAudioOn} toggleAudio={toggleAudio} />
                  <Footer3 />
                </>
              }
            ></Route>
            <Route
              exact={true}
              path="/state-of-play"
              element={
                <>
                  <NewNavbar />
                  <StateofPlay />
                  <AIChatBot />
                  <Footer3 />
                </>
              }
            ></Route>
            {/* <Route
              exact={true}
              path="/qube-tokenomics"
              element={
                <>
                  <NewNavbar />
                  <QubeTokenomics />
                  <AIChatBot />
                  <Footer3 />
                </>
              }
            ></Route> */}
            {/* commented this 3/21/24 */}
            {/* <Route
              exact={true}
              path="/philosophy"
              element={
                <>
                  <NewNavbar />
                  <Philosophy />
                  <AIChatBot />
                  <Footer3 />
                </>
              }
            ></Route> */}
            <Route
              exact={true}
              path="/DAO-projects"
              element={
                <>
                  <NewNavbar />
                  <DAOProjects />
                  <AIChatBot />
                  <Footer3 />
                </>
              }
            ></Route>
            <Route
              exact={true}
              path="/blogs/architecture-ai-and-blockchain"
              element={
                <>
                  <NewNavbar />
                  <AchitectureAiAndBlockchain />
                  <AIChatBot />
                  <Footer3 />
                </>
              }
            ></Route>
            <Route
              exact={true}
              path="/blogs/is-home-building-ripe-for-a-revolutionary"
              element={
                <>
                  <NewNavbar />
                  <IsHomeBuildingRipeForARevolutionary />
                  <AIChatBot />
                  <Footer3 />
                </>
              }
            ></Route>
            <Route
              exact={true}
              path="/blogs/NFTs-for-home-parts-and-design"
              element={
                <>
                  <NewNavbar />
                  <NFTSforHomePartsandDesign />
                  <AIChatBot />
                  <Footer3 />
                </>
              }
            ></Route>

            <Route
              exact={true}
              path="/blogs/our-qubes-toward-carbon-neutrality"
              element={
                <>
                  <NewNavbar />
                  <OurQubesTowardCarbonNeutrality />
                  <AIChatBot />
                  <Footer3 />
                </>
              }
            ></Route>
            <Route
              exact={true}
              path="/blogs/print-an-entire-house"
              element={
                <>
                  <NewNavbar />
                  <PrintanEntireHouse />
                  <AIChatBot />
                  <Footer3 />
                </>
              }
            ></Route>

            <Route
              exact={true}
              path="/blogs/the-future-of-the-build-environment"
              element={
                <>
                  <NewNavbar />
                  <TheFutureOfBuiltEnivorment />
                  <AIChatBot />
                  <Footer3 />
                </>
              }
            ></Route>

            <Route
              exact={true}
              path="/blogs/what-is-the-bottom-up-approach"
              element={
                <>
                  <NewNavbar />
                  <WhatIsBottomUpApproarch />
                  <AIChatBot />
                  <Footer3 />
                </>
              }
            ></Route>

            <Route
              exact={true}
              path="/news/homeqube-is-new-ai"
              element={
                <>
                  <NewNavbar />
                  <HqIsNewAi />
                  <AIChatBot />
                  <Footer3 />
                </>
              }
            ></Route>

            <Route
              exact={true}
              path="/news/next-gen-architecture"
              element={
                <>
                  <NewNavbar />
                  <NextGenArchitecture />
                  <AIChatBot />
                  <Footer3 />
                </>
              }
            ></Route>

            <Route
              exact={true}
              path="/resources-faqs"
              element={
                <>
                  <NewNavbar />
                  <Faqs />
                  <AIChatBot />
                  <Footer3 />
                </>
              }
            ></Route>
            <Route
              exact={true}
              path="/terms-of-use"
              element={
                <>
                  <NewNavbar />
                  <Terms />
                  <AIChatBot />
                  <Footer3 />
                </>
              }
            ></Route>
            <Route
              exact={true}
              path="/privacy-policy"
              element={
                <>
                  <NewNavbar />
                  <Policy />
                  <AIChatBot />
                  <Footer3 />
                </>
              }
            ></Route>
            {/* <Route
              exact={true}
              path="/buy-qubes"
              element={
                <>
                  <NewNavbar />
                  <BuyQubes />
                  <Footer3 />
                </>
              }
            ></Route>
            <Route path="success" element={<SuccessStripePayment />} /> */}

            {/*------------------------- Old LINK/PAGES  -----------------------------------*/}
            {/* <Route
            exact={true}
            path="/stakeholders"
            element={
              <>
                <NavBar />
                <Stakeholders />
                <Footer3 />
              </>
            } 
          ></Route>
          <Route
            exact={true}
            path="/qube-tokenomics"
            element={
              <>
                <NavBar />
                <QubeTokenomics />
                <Footer3 />
              </>
            }
          ></Route>
          <Route
            exact={true}
            path="/carbon-credit"
            element={
              <>
                <NavBar />
                <CarbonCredit />
                <Footer3 />
              </>
            }
          ></Route>
          <Route
            exact={true}
            path="/ip-assets"
            element={
              <>
                <NavBar />
                <IpAssets />
                <Footer3 />
              </>
            }
          ></Route>
          <Route
            exact={true}
            path="/about"
            element={
              <>
                <NavBar />
                <About />
                <Footer3 />
              </>
            }
          ></Route>
          <Route
            exact={true}
            path="/about-people"
            element={
              <>
                <NavBar />
                <People />
                <Footer3 />
              </>
            }
          ></Route>
          <Route
            exact={true}
            path="/about-philosophy"
            element={
              <>
                <NavBar />
                <Philosophy />
                <Footer3 />
              </>
            }
          ></Route>

          <Route
            exact={true}
            path="/resources-blogs"
            element={
              <>
                <NavBar />
                <Blog />
                <Footer3 />
              </>
            }
          ></Route>

          <Route
            exact={true}
            path="/resources-blogs-architecture-ai-and-blockchain"
            element={
              <>
                <NavBar />
                <AchitectureAiAndBlockchain />
                <Footer3 />
              </>
            }
          ></Route>
          <Route
            exact={true}
            path="/resources-blogs-is-home-building-ripe-for-a-revolutionary"
            element={
              <>
                <NavBar />
                <IsHomeBuildingRipeForARevolutionary />
                <Footer3 />
              </>
            }
          ></Route>
          <Route
            exact={true}
            path="/resources-blogs-NFTs-for-home-parts-and-design"
            element={
              <>
                <NavBar />
                <NFTSforHomePartsandDesign />
                <Footer3 />
              </>
            }
          ></Route>

          <Route
            exact={true}
            path="/resources-blogs-our-qubes-toward-carbon-neutrality"
            element={
              <>
                <NavBar />
                <OurQubesTowardCarbonNeutrality />
                <Footer3 />
              </>
            }
          ></Route>
          <Route
            exact={true}
            path="/resources-blogs-print-an-entire-house"
            element={
              <>
                <NavBar />
                <PrintanEntireHouse />
                <Footer3 />
              </>
            }
          ></Route>

          <Route
            exact={true}
            path="/resources-blogs-the-future-of-the-build-environment"
            element={
              <>
                <NavBar />
                <TheFutureOfBuiltEnivorment />
                <Footer3 />
              </>
            }
          ></Route>

          <Route
            exact={true}
            path="/resources-blogs-what-is-the-bottom-up-approach"
            element={
              <>
                <NavBar />
                <WhatIsBottomUpApproarch />
                <Footer3 />
              </>
            }
          ></Route>

          <Route
            exact={true}
            path="/resources-news"
            element={
              <>
                <NavBar />
                <News />
                <Footer3 />
              </>
            }
          ></Route>

          <Route
            exact={true}
            path="/resources-news-homeqube-is-new-ai"
            element={
              <>
                <NavBar />
                <HqIsNewAi />
                <Footer3 />
              </>
            }
          ></Route>

          <Route
            exact={true}
            path="/resources-news-next-gen-architecture"
            element={
              <>
                <NavBar />
                <NextGenArchitecture />
                <Footer3 />
              </>
            }
          ></Route>


          <Route
            exact={true}
            path="/resources-faqs"
            element={
              <>
                <NavBar />
                <Faqs />
                <Footer3 />
              </>
            }
          ></Route>
          <Route
            exact={true}
            path="/resources-esg"
            element={
              <>
                <NavBar />
                <Esg />
                <Footer3 />
              </>
            }
          ></Route>
          <Route
            exact={true}
            path="/terms-of-use"
            element={
              <>
                <NavBar />
                <Terms />
                <Footer3 />
              </>
            }
          ></Route>
          <Route
            exact={true}
            path="/privacy-policy"
            element={
              <>
                <NavBar />
                <Policy />
                <Footer3 />
              </>
            }
          ></Route>

          <Route
            exact={true}
            path="/DAO-projects"
            element={
              <>
                <NavBar />
                <DAOProjects />
                <Footer3 />
              </>
            }
          ></Route>

          <Route
            exact={true}
            path="/qube-exchange"
            element={
              <>
                <NavBar />
                <main>
                  <QubeExchange />
                </main>
                <Footer3 />
              </>
            }
          ></Route> */}

            {/* --------------------------------------------- */}
          </Routes>
        </ScrollToTop>
      </Router>
    </>
  );
}

export default App;
