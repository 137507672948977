import React from 'react';
import { SpeakerWaveIcon, SpeakerXMarkIcon } from '@heroicons/react/24/solid';

export default function AudioToggle({ isAudioOn, toggleAudio }) {
    return (
        <div className="audio-footer">
            <button className="audio-toggle-button" onClick={toggleAudio}>
                {isAudioOn ? (
                    <SpeakerWaveIcon className="icon" />
                ) : (
                    <SpeakerXMarkIcon className="icon jiggle" />
                )}
            </button>
        </div>
    );
}
