import React from "react";
import newDiagram from "../../images/NewImages/diagramLatest.svg"
// import diagram from "../../images/Pipeline/Diagram.svg";

function SED() {
  return (
    <>
      <section class="content-eco1-bg-2 pb-5" id="Sed">
        <div class="col link d-flex align-items justify-content-center py-5">
          <div class="content-bg w-100">
            <div className="sed-content">
              <div className="col w-100">
                <div className="SED-title text-center mb-5">
                  Community Growth
                  <br /> on web 3.0 and Industry 5.0
                </div>
              </div>
              <div className="col">
                <img class="img-fluid" src={newDiagram} alt="SED Diagram" />
              </div>
              <div className="col w-100">
                <div className="SED-content text-center w-100 mt-4 py-2">
                  “A decentralized platform is about
                  leveraging community potential for better
                  industry performance.”
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SED;
