import React from "react";
import Roadmap from "../modules/homepage-components/Roadmap";
import SED from "../modules/homepage-components/SED";
import ShowCaseHeader from "../modules/homepage-components/ShowCaseHeader";
import NewMission from "../modules/homepage-components/NewMission";
// import Beliefs from "../modules/homepage-components/Beliefs";
// import Context from "../modules/homepage-components/Context";
// import homepageIMG from "../images/NewImages/homepageIMG.svg";

// import TechnologyAgenda from "../modules/homepage-components/TechnologyAgenda";
// import ContextResponsive from "../modules/homepage-components/Context-responsive";
// import Partners from "../modules/homepage-components/Partners";
// import TGEEvents from "../modules/homepage-components/TGE-Events";
import VideoPlayer from "../components/VideoPlayer";
import TrapsSection from "../modules/homepage-components/TrapsSection";
import BuiltEnvironmentEngineSection from "../modules/homepage-components/BuiltEnvironmentEngineSection"
import MediaPartners from "../modules/homepage-components/MediaPartners";
import NewGenSection from "../modules/homepage-components/NewGenSection";
import QubeTokenSection from "../modules/homepage-components/QubeTokenSection";
import ShopSection from "../modules/homepage-components/ShopSection";
import PebSection from "../modules/homepage-components/PebSection";
import IndustryPartners from "../modules/homepage-components/IndustryPartners";
import DaoSection from "../modules/homepage-components/DaoSection";
import CommunitySection from "../modules/homepage-components/CommunitySection";
import DappSection from "../modules/homepage-components/DappSection";
import ScrollTimeline from "../modules/homepage-components/ScrollTimeline"

function Homepage() {
  const sections = ["Hero", "$QUBE Token", "Bidding Shop", "DApp", "PEB", "DAO", "Community", "NewGen"];
  return (
    <>
      <ScrollTimeline sectionIds={sections} />
      <ShowCaseHeader />
      <NewMission />
      <TrapsSection />
      <BuiltEnvironmentEngineSection />
      <SED />
      <QubeTokenSection />
      <ShopSection />
      <MediaPartners />
      <DappSection />
      <PebSection />
      <IndustryPartners />
      <DaoSection />
      <CommunitySection />
      <VideoPlayer />
      <NewGenSection />
    </>
  );
}

export default Homepage;
