import React from "react";

const VideoPlayer = () => {
  return (
    <>
      <video width="100%" height="100%" autoPlay loop controls muted ="Teaser">
        <source
          src="https://s3.amazonaws.com/www.qube.homeqube.com/video/HQ_AI_Teaser.mov"
          type="video/mp4"
        />
        <source
          src="https://s3.amazonaws.com/www.qube.homeqube.com/video/Homeqube-ICO.ogg"
          type="video/ogg"
        />
      </video>
    </>
  );
};

export default VideoPlayer;
